import { Controller } from "@hotwired/stimulus"
import { loadStripe } from "@stripe/stripe-js"

export default class extends Controller {
  static targets = ["paymentElement", "error"]
  static values = {
    clientSecret: String,
    customerEmail: String,
    currency: String,
    locale: String,
    publicKey: String,
    returnUrl: String,
    paymentMethods: Array,
  }

  connect() {
    this.loadStripe()
  }

  loadStripe() {
    const stripePromise = loadStripe(this.publicKeyValue)
    stripePromise.then((stripe) => {
      this.stripe = stripe
      this.elements = stripe.elements({
        clientSecret: this.clientSecretValue,
        locale: this.localeValue,
        currency: this.currencyValue,
        appearance: this.appearance(),
      })

      let paymentElementInstance = this.elements.create("payment", {
        paymentMethodOrder: this.paymentMethodsValue,
        fields: {
          billingDetails: { email: "never" },
        },
      })
      paymentElementInstance.mount(this.paymentElementTarget)
    })
  }

  submit(event) {
    event.preventDefault()

    this.submitPaymentElement()

    const { error } = this.confirmSetup(this.elements)

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      this.handleError(error)
    }
  }

  async submitPaymentElement() {
    const { error: submitError } = await this.elements.submit()
    if (submitError) {
      this.handleError(submitError)
    }
  }

  async confirmSetup(elements) {
    await this.stripe.confirmSetup({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email: this.customerEmailValue,
          },
        },
        return_url: this.returnUrlValue,
      },
    })
  }

  handleError(error) {
    this.errorTarget.textContent = error.message
  }

  appearance() {
    this.styles = window.getComputedStyle(document.documentElement)
    return {
      theme: "stripe",
      variables: {
        colorPrimary: this.getCssVariableValue("--color-primary-500"),
        colorBackground: "#ffffff",
        colorText: "#30313d",
        gridRowSpacing: "16px",
      },
      rules: {
        ".Tab": {
          borderRadius: "2px",
          borderColor: "#d1d5db",
        },
        ".TabLabel--selected": { color: "#111827" },
        ".Input": {
          borderColor: "#D1D5DB",
          borderRadius: "2px",
        },
        ".Input:focus": {
          borderColor: this.getCssVariableValue("--color-primary-600"),
          boxShadow: `0 0 0 1px ${this.getCssVariableValue("--color-primary-500")}, 0 0 0 3px ${this.getCssVariableValue("--color-primary-100")}`,
        },
        ".Label": { color: "#374151", fontWeight: "600", marginBottom: "6px" },
      },
    }
  }

  getCssVariableValue(variable) {
    return `rgb(${this.styles.getPropertyValue(variable)})`
  }
}
