import { AnalyticsBrowser, InitOptions } from "@segment/analytics-next"

const element = document.getElementById("segment")
const dataset = element.dataset
const analytics = new AnalyticsBrowser()

globalThis.analytics = analytics

export function loadAnalytics() {
  analytics.load({ writeKey: dataset.segmentWriteKey }, { disableClientPersistence: true })
}

export function identifyUser() {
  analytics.identify(dataset.userId)
}

export function identifyDebt() {
  analytics.identify({ ...debtData(), ...experimentData() })
}

function debtData() {
  return {
    customerPrefixId: dataset.customerId,
    debtPrefixId: dataset.debtId,
    debtorCategory: dataset.debtorCategory,
    organisationPrefixId: dataset.organisationId,
  }
}

function experimentData() {
  const experiments = {}
  Object.entries(dataset).forEach(([key, value]) => {
    if (key.startsWith("experiment")) {
      experiments[key] = value
    }
  })
  return experiments
}
