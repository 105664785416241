import "packages/web"
import "../components"

// TODO(packwerk): Migrate these to appropriate packages
import "app/components"
import "app/javascript/controllers"

import { identifyDebt, identifyUser, loadAnalytics } from "packages/integrations/segment"

loadAnalytics()

let element = document.getElementById("segment")

if (element.dataset.debtId !== undefined) {
  identifyDebt()
}

if (element.dataset.userId !== undefined) {
  identifyUser()
}
